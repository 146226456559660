import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import GamesLayout from '../components/GamesLayout';
import { Spacer } from './allgames';
import GameControlNavigator from '../components/GameControlNavigator';
import SEO from '../components/SEO';
import useMatchedQuery from '../hooks/useMatchedQuery';

const useSEO = () => {
  const { contentfulComponentSeo, site } = useStaticQuery(
    graphql`
    query SEOgamesForSaleSelection {
      contentfulComponentSeo(name: {eq: "games-for-sale"}, node_locale: {eq: "es-AR"}) {
        title
        description {
          description
        }
        image {
          gatsbyImageData(
            layout: FIXED,
            width: 600,
            height: 600
          )
          id
          title
        }
        name
        type
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
    `
  )

  return { ...contentfulComponentSeo, siteUrl: site.siteMetadata.siteUrl }
}

const getCity = (city) => (city.contentful_id || city);

const GamesSelectionPage = (props) => {
  const { city, games, total, title, seo } = props.pageContext;
  const contentfulComponentSeo = useSEO()
  const matchedQuery = useMatchedQuery()
  const image = getSrc(seo.image)

  return (
    <>
      <SEO {...contentfulComponentSeo} {...props.pageContext} pagePath={`/${city}/juegosEnVenta`} seo={{...contentfulComponentSeo, image}} />
      <GamesLayout title={title} {...props.pageContext} displayContact={false} displayDataFiscal={false}>
        <Spacer />
        <GameControlNavigator {...props} height={'100vh'} city={city} preloaded={{games, totalCount: total}} prefix={`gamesForSale/${getCity(city)}`} matchedQuery={matchedQuery} />
      </GamesLayout>
    </>
  )
}

export default GamesSelectionPage;
